<script lang="js" setup>
const { t } = useT();
const route = useRoute();
const { seoData } = useHomePage();
const { data: appInitData } = useAppInitData();
const { scrollToContent, content } = useScrollTo();
const { handleSupportButtonClick } = useSupportChat();
const [expanded, toggleExpanded] = useToggle();
const { feedback } = route.query;

const currentYear = new Date().getFullYear();

const footerLinks = computed(
	() => appInitData.value?.footer?.menu?.find((item) => item?.title === "second_column")?.items
);

const footerSeoText = computed(
	() =>
		({
			title: seoData.value?.seoH1,
			text: seoData.value?.seoText
		}) || {}
);

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "footer",
		button_name: "support"
	});
	handleSupportButtonClick();
};

onMounted(() => {
	if (feedback) {
		setTimeout(() => {
			scrollToContent(-100);
		}, 50);
	}
});
</script>

<template>
	<footer ref="content" class="footer">
		<div class="wrapper">
			<div class="info">
				<div class="box-description">
					<div class="about" data-tid="footer-about">
						<AText variant="toledo" :modifiers="['semibold']" class="text-cannes">{{ t("About FortuneWheelz") }}</AText>
						<div class="about-text">
							<AText>{{
								t(
									"NO PURCHASE IS NECESSARY to enter free game promotion (“promotional games”). PROMOTIONAL GAMES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see Terms of Use."
								)
							}}</AText>
							<AText>{{
								t(
									"Fortune Wheelz Social Gaming Platform is a play-for-fun website intended for amusement purposes only. NoLimitCoins Social Gaming Platform does not offer “real-money gambling” or opportunity to win real money based on a gameplay."
								)
							}}</AText>
							<AText>{{
								t(
									"Fortune Wheelz Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry."
								)
							}}</AText>
						</div>
					</div>
					<div class="info bottom">
						<div v-if="!!footerSeoText" class="accordion">
							<ACollapse :expanded="expanded">
								<template #title>
									<div
										:class="['accordion-header', { expanded: expanded }]"
										data-tid="footer-accordion"
										@click="toggleExpanded()"
									>
										<AText variant="toledo" :modifiers="['semibold']">
											{{ footerSeoText?.title }}
										</AText>
										<ASvg name="24/arrow-down" :class="['accordion-icon', { expanded: expanded }]" />
									</div>
								</template>
								<template #expanded>
									<div class="accordion-content" v-html="footerSeoText?.text" />
								</template>
							</ACollapse>
						</div>
					</div>
				</div>
				<div class="box-option" data-tid="footer-feedback">
					<ClientOnly>
						<OFeedback />
					</ClientOnly>

					<div class="contacts">
						<div class="help">
							<AText class="text-cannes" :modifiers="['semibold']">{{ t("Need help?") }}</AText>
							<div class="help-content">
								<div class="help-item" @click="handleSupportClick">
									<ASvg name="24/support" class="help-icon" />
									<AText :modifiers="['underline']" class="help-text" data-tid="footer-support">{{
										t("Online support")
									}}</AText>
								</div>
								<div class="help-item">
									<ASvg name="24/email" class="help-icon" />
									<a href="mailto:support@fortunewheelz.com" data-tid="footer-support-email">
										<AText :modifiers="['underline']" class="help-text">support@fortunewheelz.com</AText>
									</a>
								</div>
							</div>
						</div>
						<div v-if="appInitData?.socialNetworks?.length" class="socials">
							<NuxtLink
								v-for="(item, index) in appInitData?.socialNetworks"
								:key="index"
								:data-tid="`footer-social-${item.id}`"
								:to="item?.url"
								target="_blank"
							>
								<div v-html="item?.icon" />
							</NuxtLink>
						</div>
						<NuxtLink
							class="icon-trustpilot"
							data-tid="footer-trustpilot"
							to="https://www.trustpilot.com/review/fortunewheelz.com"
							external
							target="_blank"
						>
							<NuxtImg src="/nuxt-img/footer/trustpilot.svg" alt="trustpilot" width="150" height="55" loading="lazy" />
						</NuxtLink>
					</div>
				</div>
			</div>

			<div class="rules">
				<NuxtImg
					src="nuxt-img/logo-footer.png"
					format="webp"
					width="96"
					height="64"
					class="logo"
					alt="logo"
					loading="lazy"
				/>
				<div v-if="footerLinks?.length" class="links">
					<template v-for="(link, index) in footerLinks" :key="`${link?.title}-keyName`">
						<NuxtLink
							v-if="!(link?.url === '/promotional-games-rules/' && !appInitData?.hasDeposits)"
							:data-tid="`footer-terms-${index}`"
							:to="`/page${link?.url}`"
						>
							<AText variant="topeka" :modifiers="['underline', 'link']">{{ link?.title }}</AText>
						</NuxtLink>
					</template>
				</div>
			</div>
			<AText variant="topeka" :modifiers="['center']" as="div" class="copyright" data-tid="footer-copyright">{{
				t("© Fortune Wheelz Social Gaming Platform", { msg: currentYear })
			}}</AText>
		</div>
	</footer>
</template>

<style scoped lang="scss">
.flex-column {
	display: flex;
	flex-direction: column;
}

.footer {
	@include media-breakpoint-up(lg) {
		margin-left: calc(var(--o-sidebar-width) + gutter(3));
		width: calc(100% - var(--o-sidebar-width) - gutter(3));
	}

	.help-icon {
		font-size: 24px;
	}

	.wrapper {
		@extend .flex-column;
		width: 100%;
		max-width: 1136px;
		margin: 0 auto;
		gap: gutter(3);
		background: var(--craiova);
		padding: gutter(3) gutter(2);

		@include media-breakpoint-up(lg) {
			padding: gutter(4);
			padding-bottom: gutter(3);
		}
	}

	.contacts {
		@extend .flex-column;
		gap: gutter(3);

		@include media-breakpoint-up(lg) {
			min-width: 328px;
		}
	}

	.info {
		@extend .flex-column;
		gap: gutter(3);

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			align-items: flex-start;

			&.bottom {
				order: 2;
				margin-top: gutter(1);
			}
		}

		@include media-breakpoint-down(lg) {
			flex-direction: column-reverse;
		}
	}

	.about {
		@extend .flex-column;
		gap: gutter(2);
		padding-top: gutter(3);
		border-top: 1px solid var(--changchun);
		color: var(--cocoa);

		&.bottom {
			border-top: 0;
			padding: 0;
		}

		&.desk {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			border-top: 0;
			padding: 0;
			&.mob {
				display: none;
			}

			&.desk {
				display: flex;
			}

			&.bottom {
				width: 328px;
			}
		}
	}
}

.about-text {
	@extend .flex-column;
	gap: gutter(1.5);
}

.help {
	@extend .flex-column;
	justify-content: center;
	gap: gutter(2);
	color: var(--caracas);
}
.help-item {
	display: flex;
	align-items: center;
	gap: gutter(1);
	color: var(--caracas);
	cursor: pointer;
}

.help-content {
	@extend .flex-column;
	gap: gutter(2);
}

.help-text {
	color: var(--caracas);
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

.socials {
	display: flex;
	gap: gutter(1);
	font-size: 32px;
	color: var(--cocoa);
}

.trustpilot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--cocoa);
	font-size: 32px;

	@include media-breakpoint-up(lg) {
		justify-content: flex-start;
		order: 1;
		gap: gutter(4);
	}
}

.box-description {
	@extend .flex-column;
	gap: gutter(3);

	@include media-breakpoint-up(lg) {
		gap: gutter(2);
	}
}

.lg-hidden {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.accordion {
	padding: gutter(2) 0;
	border-bottom: 1px solid var(--coyah);

	@include media-breakpoint-up(lg) {
		flex: 1;
	}
}

.accordion-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	line-height: 24px;

	&.expanded {
		color: var(--chicago);
	}
}
.accordion-icon {
	cursor: pointer;
	font-size: 24px;
	transition: transform 0.3s ease;

	&.expanded {
		transform: rotate(-180deg);
	}
}

.accordion-content {
	padding-top: gutter(2);
	font-size: 12px;
	line-height: 14px;
	&:deep {
		h2,
		h3 {
			margin-bottom: gutter(1.5);
		}
		p {
			margin-bottom: gutter(1);
		}

		ul {
			padding-left: gutter(1.25);
			list-style-type: disc;
			> li {
				margin-bottom: gutter(1.5);
			}
		}
	}
}

.rules,
.links {
	@extend .flex-column;

	gap: gutter(1.5);
}

.rules {
	padding-bottom: gutter(3);
	border-bottom: 1px solid var(--coyah);

	@include media-breakpoint-up(lg) {
		padding: gutter(3) 0;
		flex-direction: row;
		justify-content: space-between;
		border-top: 1px solid var(--coyah);
	}
}

.links {
	@include media-breakpoint-up(lg) {
		flex-direction: row;
		gap: gutter(4);
	}
}

.link {
	color: var(--cocoa);

	&:hover {
		text-decoration: none;
	}
}

.logo {
	height: 32px;
	width: 48px;
}

.icon-trustpilot {
	margin-top: gutter(-1.5);
}
</style>
